import type {
  AddToWishlistMutationVariables,
  RemoveFromWishlistMutationVariables,
} from '#gql'

function useWishlistQueries() {
  /**
   * Returns active wishlist for the logged-in customer.
   */
  const getCustomerWishlist = async (attributes: any) =>
    await GqlCustomerWishlist(attributes)

  /**
   * Adds product to wishlist
   */
  const addProductsToWishlist = async (
    variables: AddToWishlistMutationVariables,
  ) => {
    const { addProductsToWishlist } = await GqlAddToWishlist(variables)
    return addProductsToWishlist
  }

  /**
   * Removes product from wishlist
   */
  const removeProductsFromWishlist = async (
    variables: RemoveFromWishlistMutationVariables,
  ) => {
    const { removeProductsFromWishlist }
      = await GqlRemoveFromWishlist(variables)
    return removeProductsFromWishlist
  }

  return {
    getCustomerWishlist,
    addProductsToWishlist,
    removeProductsFromWishlist,
  }
}
export { useWishlistQueries }
