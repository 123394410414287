import { storeToRefs } from 'pinia'
import { useWishlistStore } from '@customer/stores/wishlist'

function useWishlist() {
  const wishlistStore = useWishlistStore()

  const { pending, errorState, wishlist, loadingItems }
    = storeToRefs(wishlistStore)
  const {
    getWishlist,
    addToWishlist,
    removeFromWishlist,
    removeFromWishlistById,
    toggleWishlistItem,
    findWishlistProduct,
    isItemInWishlist,
    shouldItemBeDisabled,
  } = wishlistStore

  return {
    pending: readonly(pending),
    errorState: readonly(errorState),
    wishlist,
    loadingItems,
    getWishlist,
    addToWishlist,
    removeFromWishlist,
    removeFromWishlistById,
    toggleWishlistItem,
    findWishlistProduct,
    isItemInWishlist,
    shouldItemBeDisabled,
  }
}

export { useWishlist }
