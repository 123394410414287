import { defineStore } from 'pinia'
import { useEcomGqlUserErrors } from '@ecom/composables/useEcomGqlUserErrors/useEcomGqlUserErrors'
import { mapProductsAttributesList } from '@ecom/helpers/products'
import { useNotification } from '@base/composables/useNotifications'
import { useWishlistQueries } from '@customer/composables/useWishlist/useWishlistQueries'
import { useCustomerStore } from '@customer/stores/customer'
import type { NotificationComponentType } from '@base/stores/notifications.types'
import type {
  CustomerWishlist,
  CustomerWishlistErrors,
  CustomerWishlistProduct,
} from '../types/wishlist'
import type {
  AddToWishlistMutationVariables,
  RemoveFromWishlistMutationVariables,
} from '#gql'

export const useWishlistStore = defineStore('wishlist', () => {
  const pending = ref<boolean>(false)
  const loadingItems = ref<string[]>([])
  const errorState = ref<CustomerWishlistErrors>({
    load: null,
    addItem: null,
    removeItem: null,
  })
  const wishlist = ref<CustomerWishlist | null>(null)

  const { $i18n, callHook } = useNuxtApp()
  // Method is available on $i18n composer instance
  const { t } = $i18n
  const { handleUserError } = useEcomGqlUserErrors()
  const customerStore = useCustomerStore()
  const { refreshSectionData } = customerStore
  const addToWishlisttNotificationType = 'toast' as NotificationComponentType
  const { showNotification } = useNotification(addToWishlisttNotificationType)

  const getWishlist = async () => {
    pending.value = true
    errorState.value.load = null
    const attributesList = [
      'ecommerce_packing_type',
      'erp_packaging_and_quantity',
      'brand',
    ]
    const attributesArray = mapProductsAttributesList(attributesList)

    try {
      const { getCustomerWishlist } = useWishlistQueries()
      const { customer } = await getCustomerWishlist({
        attributes: attributesArray,
      })

      const customerWishlist = customer?.wishlists?.[0] ?? null

      if (customerWishlist) {
        const {
          id,
          items_count: itemsCount,
          items_v2: itemsV2,
        } = customerWishlist

        wishlist.value = {
          id: id || '',
          itemsCount: itemsCount || 0,
          items: itemsV2?.items ?? [],
        }
      }
      return customerWishlist
    }
    catch (error) {
      errorState.value = error
    }
    finally {
      pending.value = false
    }
  }

  const _addProductsToWishlist = async (
    params: AddToWishlistMutationVariables,
  ) => {
    pending.value = true
    errorState.value.addItem = null
    try {
      const { addProductsToWishlist } = useWishlistQueries()
      const payload = { ...params }
      if (!payload.wishlistId) {
        const customerWishlist = await getWishlist()
        Object.assign(payload, { ...payload, wishlistId: customerWishlist?.id })
      }

      const updatedWishlist = await addProductsToWishlist(payload)
      const { user_errors: userErrors = [] } = updatedWishlist

      if (Array.isArray(userErrors) && userErrors?.length) {
        handleUserError(userErrors)
        throw userErrors
      }
      showWishlistNotification({
        type: 'success',
        message: t(
          'customer_successfully_added_to_wishlist',
        ),
      })
      return updatedWishlist
    }
    catch (error) {
      errorState.value.addItem = error
    }
    finally {
      pending.value = false
    }
  }

  const addToWishlist = async (
    product: CustomerWishlistProduct,
    wishlistId = null,
  ) => {
    if (!product?.uid || !product?.sku) {
      showWishlistNotification({
        type: 'error',
        message: t('ecommerce.messages.errors.somethig_went_wrong'),
      })
      return
    }

    try {
      if (!wishlistId && !wishlist.value?.id) {
        await getWishlist()
      }

      let wishlistProduct = null
      if (wishlist.value) {
        wishlistProduct = findWishlistProduct(product)
      }

      if (wishlistProduct) {
        return
      }

      loadingItems.value.push(product.uid)

      switch (product.__typename) {
        case 'SimpleProduct': {
          await _addProductsToWishlist({
            wishlistId: (wishlist.value?.id ?? '').toString(),
            wishlistItems: [
              {
                sku: product.sku,
                quantity: 1,
              },
            ],
          })

          callHook('customer:wishlist-add', {
            sku: product.sku,
          })

          break
        }
        default:
          console.error(
            t(
              'customer_cannot_add_this_type_of_product_to_the_wishlist',
            ),
          )
      }
      await getWishlist()
      await refreshSectionData()
    }
    finally {
      loadingItems.value = loadingItems.value.filter(
        item => item !== product.uid,
      )
    }
  }

  const _removeProductsFromWishlist = async (
    params: RemoveFromWishlistMutationVariables,
  ) => {
    pending.value = true
    errorState.value.removeItem = null
    try {
      const { removeProductsFromWishlist } = useWishlistQueries()

      const updatedWishlist = await removeProductsFromWishlist(params)
      const { user_errors: userErrors = [] } = updatedWishlist

      if (Array.isArray(userErrors) && userErrors?.length) {
        handleUserError(userErrors)
        throw userErrors
      }

      showWishlistNotification({
        type: 'success',
        message: t(
          'customer_successfully_removed_from_wishlist',
        ),
      })
      return updatedWishlist
    }
    catch (error) {
      errorState.value.removeItem = error
    }
    finally {
      pending.value = false
    }
  }

  const removeFromWishlist = async (
    product: CustomerWishlistProduct,
    wishlistId = null,
  ) => {
    if (!product?.uid || !product?.sku) {
      showWishlistNotification({
        type: 'error',
        message: t('ecommerce.messages.errors.somethig_went_wrong'),
      })
      return
    }

    try {
      if (!wishlistId && !wishlist.value?.id) {
        await getWishlist()
      }

      let wishlistProduct = null
      if (wishlist.value) {
        wishlistProduct = findWishlistProduct(product)
      }

      if (!wishlistProduct?.id) {
        return
      }

      loadingItems.value.push(product.uid)

      await _removeProductsFromWishlist({
        wishlistId: (wishlist.value?.id ?? '').toString(),
        wishlistItemsIds: [(wishlistProduct.id.toString())],
      })

      callHook('customer:wishlist-remove', {
        sku: wishlistProduct.id,
      })

      await getWishlist()
      await refreshSectionData()
    }
    finally {
      loadingItems.value = loadingItems.value.filter(
        item => item !== product.uid,
      )
    }
  }

  const removeFromWishlistById = async (
    wishListIds: string[],
    wishlistId: string | number,
  ) => {
    try {
      wishListIds.forEach((el: any) => {
        loadingItems.value.push(el)
      })
      await _removeProductsFromWishlist({
        wishlistId: wishlistId.toString(),
        wishlistItemsIds: wishListIds,
      })
      await getWishlist()
      await refreshSectionData()
    }
    finally {
      wishListIds.forEach((el: any) => {
        loadingItems.value = loadingItems.value.filter(item => item !== el)
      })
    }
  }

  const findWishlistProduct = (
    product: CustomerWishlistProduct,
  ) => {
    if (!wishlist.value || !product) {
      return null
    }
    return (
      wishlist.value?.items?.find((wishlistItem) => {
        return (
          wishlistItem.product?.uid === product.uid
          && wishlistItem.product?.sku === product.sku
        )
      }) ?? null
    )
  }

  const showWishlistNotification = ({
    type,
    message,
  }: {
    type: any
    message: string
  }) => {
    showNotification({
      id: crypto.randomUUID(),
      type,
      message,
      closable: false,
      timeout: 3000,
    })
  }

  const isItemInWishlist = (product: CustomerWishlistProduct) => {
    if (!product || !wishlist.value) {
      return false
    }
    const item = findWishlistProduct(product)
    return Boolean(item?.product?.uid && item?.product.sku)
  }
  /**
   * Adds (if not found) or removes (if found) item from wishlist
   * @param product
   */
  const toggleWishlistItem = async (product: CustomerWishlistProduct) => {
    if (!product) {
      return
    }

    return isItemInWishlist(product)
      ? await removeFromWishlist(product)
      : await addToWishlist(product)
  }

  const shouldItemBeDisabled = (product: CustomerWishlistProduct | null) => {
    if (!product?.uid) {
      return true
    }

    return loadingItems.value.includes(product.uid)
  }

  return {
    pending,
    errorState,
    wishlist,
    loadingItems,
    getWishlist,
    addToWishlist,
    removeFromWishlist,
    removeFromWishlistById,
    toggleWishlistItem,
    findWishlistProduct,
    isItemInWishlist,
    shouldItemBeDisabled,
  }
})
